import React, {Component} from 'react';
import {bigScreenActions} from '../store/actions/BigScreenActions';
import {eventActions} from '../store/actions/EventActions';
import {connect} from 'react-redux';
import {questionActions} from '../store/actions/QuestionActions';
import {Progress} from 'reactstrap';
import {Textfit} from 'react-textfit';
import AnswerText from '../Components/AnswerText';
import AnswetTextLive from '../Components/AnswetTextLive';

import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import IntroductionPage from '../Components/IntroductionPage';
import SingleQuestionComparison from './SingleQuestionComparison';
import CompareQuestionsModal from '../Modals/CompareQuestionsModal';
import LiveCompareResultsComponent from '../Components/LiveCompareResultsComponent';
import moment from 'moment';
import RatingResponse from "../Components/RatingResponse";
import StarRatingComponent from "react-star-rating-component";
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';

class LiveScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idevent,
            idElement: this.props.match.params.idElement,
            // screenType: this.props.match.params.elementType,
            sessionId: '',
            questionId: '',
            elementId: '',
            cardTitle: '',
            responsesArray: [],
            status: '',
            questionData: [],
            optionsArrayLive: [],
            currentEvent: 'default',
            parentInfo: 'default',
            toggleActive: true,
            responsesArraySimpleText: [],
            optionCol: '',
            progressCol: '',
            progressbarHeight: '',
            optionFontSize: '',
            questionSize: '',
            statusTweet: '',
            overFlowY: '',
            optionsFontSize: '',
            optionsPadding: '',
            optionsLineHeight: '',
            optionsContainerPaddingTop: '',
            questionForOptionsFontSize: '40px',
            questionForOptionsLineHeight: '42px',
            optionsTextIndent: '',
            responsesArrayLive: [],
            optionBulletFontSize: '',
            middleContainerStatus: "default",
            votingSessionsListToCompare: [],
            maxValue: 0,
            barChartData: [],
            optionsPaddingLeft: "0px",
            allTweetsWithoutModerator: [],
            optionsToCompare: []
        }

        this.escFunction = this.escFunction.bind(this);
        this.textResizeFunction = this.textResizeFunction.bind(this);
        this.textResizeFunctionForComparison = this.textResizeFunctionForComparison.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }

        if (this.unsubscribeGetAllTweets) {
            this.unsubscribeGetAllTweets();
        }

        if (this.unsubscribeGetAllApprovedTweetsList) {
            this.unsubscribeGetAllApprovedTweetsList();
        }

        if (this.unsubscribeGetAllLiveTweetsList) {
            this.unsubscribeGetAllLiveTweetsList();
        }

        if (this.unsubscribeInitQuestionOptions) {
            this.unsubscribeInitQuestionOptions();
        }
        if (this.unsubscribeGetQuestionInfoOnChange) {
            this.unsubscribeGetQuestionInfoOnChange();
        }

    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

        if (this.unsubscribeGetEventInfoOnChange) {
            this.unsubscribeGetEventInfoOnChange();
        }
        this.props.onGetEventInfoOnChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeGetEventInfoOnChange = unsubscribe;
            this.setState({...this.state, currentEvent: callback});
            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idElement, (parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                if (callback.screenNumber === "One" && parentInfo.singleScreenElementId === parentInfo.currentQuestionElement) {
                    if (parentInfo.currentQuestion && parentInfo.currentQuestion === parentInfo.bigScreenStatus.questionId) {
                        this.props.onGetQuestionInfo(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, callBackQuestionData => {
                            if (callBackQuestionData.firstVotingSessionInitialized === false) {
                                // the user can update the question as long as the first voting session has not been initialized so we receive the question changes 
                                //otherwise we only fetch the question data without snapshot
                                if (this.unsubscribeGetQuestionInfoOnChange) {
                                    this.unsubscribeGetQuestionInfoOnChange();
                                }
                                this.props.onGetQuestionInfoOnChange(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, (questionData, unsubscribe) => {
                                    this.unsubscribeGetQuestionInfoOnChange = unsubscribe;
                                    if (this.unsubscribeInitQuestionOptions) {
                                        this.unsubscribeInitQuestionOptions();
                                    }
                                    this.props.onInitQuestionOptions(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, (callbackOptionsList, unsubscribe) => {
                                        this.unsubscribeInitQuestionOptions = unsubscribe;
                                        this.setState({
                                            ...this.state,
                                            questionData: questionData,
                                            currentEvent: callback,
                                            optionsArrayLive: callbackOptionsList,
                                            parentInfo: parentInfo,
                                            middleContainerStatus: "questionContainer"
                                        });
                                        this.textResizeFunction(questionData, callbackOptionsList);
                                    });
                                });
                            } else {
                                if (this.unsubscribeInitQuestionOptions) {
                                    this.unsubscribeInitQuestionOptions();
                                }
                                this.props.onInitQuestionOptions(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, (callbackOptionsList, unsubscribe) => {
                                    this.unsubscribeInitQuestionOptions = unsubscribe;
                                    this.setState({
                                        ...this.state,
                                        questionData: callBackQuestionData,
                                        currentEvent: callback,
                                        optionsArrayLive: callbackOptionsList,
                                        parentInfo: parentInfo,
                                        middleContainerStatus: "questionContainer"
                                    });
                                    this.textResizeFunction(callBackQuestionData, callbackOptionsList);
                                    //we get the responses
                                    if (callBackQuestionData.type === "MultipleChoice" || callBackQuestionData.type === "SimpleTextAndMultipleChoice" || callBackQuestionData.type === "RatingQuestion") {
                                        if (parentInfo.bigScreenStatus.status === "liveQuestion") {
                                            this.props.onGetResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, parentInfo.currentLiveSession.id, callbackResponses => {
                                                this.setState({
                                                    ...this.state,
                                                    responsesArray: callbackResponses,
                                                    currentEvent: callback,
                                                    parentInfo: parentInfo
                                                });
                                            });
                                        } else {
                                            //we check if the user changes the question while the big screen status is the archived question 
                                            //we will first fetch the id of the last voting session and will get the responses
                                            if (parentInfo.currentSelectedLiveSession === 'default') {
                                                this.props.onGetLastVotingSessionId(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData.id, lastVotingsession => {
                                                    this.props.onGetArchivedResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, lastVotingsession.id, callbackResponses => {
                                                        this.setState({
                                                            ...this.state,
                                                            responsesArray: callbackResponses,
                                                            currentEvent: callback,
                                                            parentInfo: parentInfo
                                                        });
                                                    });
                                                });
                                            } else {
                                                this.props.onGetArchivedResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, parentInfo.currentSelectedLiveSession, callbackResponses => {
                                                    this.setState({
                                                        ...this.state,
                                                        responsesArray: callbackResponses,
                                                        currentEvent: callback,
                                                        parentInfo: parentInfo
                                                    });
                                                });
                                            }
                                        }
                                    } else {
                                        if (parentInfo.bigScreenStatus.status === "liveQuestion") {
                                            this.props.onGetResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, parentInfo.currentLiveSession.id, callbackResponses => {
                                                this.setState({
                                                    ...this.state,
                                                    responsesArrayLive: callbackResponses,
                                                    currentEvent: callback,
                                                    parentInfo: parentInfo
                                                });
                                            });
                                        } else {
                                            this.props.onGetArchivedResponses(this.state.eventId, parentInfo.currentQuestionElement, this.state.questionData, parentInfo.currentSelectedLiveSession, callbackResponses => {
                                                this.setState({
                                                    ...this.state,
                                                    responsesArraySimpleText: callbackResponses,
                                                    currentEvent: callback,
                                                    parentInfo: parentInfo
                                                });
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                } else if (callback.screenNumber === "One" && parentInfo.singleScreenElementId === parentInfo.currentTweetElement) {
                    this.setState({
                        ...this.state,
                        currentEvent: callback,
                        parentInfo: parentInfo,
                        middleContainerStatus: "tweetContainer"
                    });
                    if (callback.autoApproveTweets === "Without Moderator") {
                        if (this.unsubscribeGetAllTweets) {
                            this.unsubscribeGetAllTweets();
                        }
                        //we show all the tweets on the big screen without need to approve or put it live
                        this.props.onGetAllTweets(this.state.eventId, parentInfo.currentTweetElement, (tweetsList, unsubscribe) => {
                            this.unsubscribeGetAllTweets = unsubscribe;
                            this.setState({
                                ...this.state,
                                allTweetsWithoutModerator: tweetsList
                            });
                        });
                    } else {
                        if (parentInfo.currentTweetElement !== "default") {
                            if (this.unsubscribeGetAllLiveTweetsList) {
                                this.unsubscribeGetAllLiveTweetsList();
                            }
                            this.props.onGetAllLiveTweetsList(this.state.eventId, parentInfo.currentTweetElement, unsubscribe => this.unsubscribeGetAllLiveTweetsList = unsubscribe);

                            if (this.unsubscribeGetAllApprovedTweetsList) {
                                this.unsubscribeGetAllApprovedTweetsList();
                            }
                            this.props.onGetAllApprovedTweetsList(this.state.eventId, parentInfo.currentTweetElement, unsubscribe => this.unsubscribeGetAllApprovedTweetsList = unsubscribe);
                        } else {
                            this.setState({
                                ...this.state,
                                status: 'NoLiveSession',
                                currentEvent: callback,
                                parentInfo: parentInfo
                            });
                        }
                    }
                } else if (callback.screenNumber === "One" && parentInfo.singleScreenElementId === parentInfo.currentQuestionElement + "-compareResults") {
                    this.props.onGetQuestionInfo(callback.id, parentInfo.currentQuestionElement, parentInfo.currentQuestion, questionData => {
                        if (parentInfo.selectedSessionsToCompare && parentInfo.selectedSessionsToCompare.length > 0) {
                            this.props.onGetVotingSessionsToCompare(this.state.eventId, parentInfo.currentQuestionElement, questionData, parentInfo.selectedSessionsToCompare, votingListCallback => {
                                
                                var description = ['Options'];
                                var dataList = [description];
                                var resultsList = [];
                                var defineTextSize = false;
                                votingListCallback.sort((a, b) => a.timeStamp > b.timeStamp ? 1 : -1);
                                if (this.unsubscribeInitQuestionOptions) {
                                    this.unsubscribeInitQuestionOptions();
                                }

                                this.props.onInitQuestionOptions(this.state.eventId, parentInfo.currentQuestionElement, questionData.id, (callbackOptionsList, unsubscribe) => {
                                    this.unsubscribeInitQuestionOptions = unsubscribe;
                                    this.setState({...this.state, optionsToCompare: callbackOptionsList});
                                if (votingListCallback && votingListCallback.length > 0) {

                                        let archivedResponses = [];

                                        for (var j = 0; j < votingListCallback.length; j++) {
                                            archivedResponses.push([]);
                                        }

                                    for (var i = 0; i < votingListCallback.length; i++) {

                                            if ((!votingListCallback[i].voted) || votingListCallback[i].voted) {

                                                //Get all responses for voting session.
                                                this.props.onGetArchivedResponses(this.state.eventId, parentInfo.currentQuestionElement, questionData, votingListCallback[i].id, callbackResponses => {

                                                    let votingsessionresponseindex = 0;
                                                    for (var j = 0; j < votingListCallback.length; j++) {
                                                        if (callbackResponses[0].votingSessionId === votingListCallback[j].id) {
                                                            votingsessionresponseindex = j;
                                                        }
                                                    }

                                                    archivedResponses[votingsessionresponseindex] = callbackResponses;

                                                    let allresponsedownloaded = true;
                                                    for (var j = 0; j < archivedResponses.length; j++) {
                                                        if (archivedResponses[j].length == 0) {
                                                            allresponsedownloaded = false;
                                                        }
                                                    }

                                                    if (allresponsedownloaded) {

                                            if (defineTextSize === false) {
                                                            this.textResizeFunctionForComparison(questionData, callbackOptionsList);
                                                defineTextSize = true;
                                            }
                                                
                                                        let finalOptionData = [];//Each element of array contain text of option, votes, percentage for pre and post voting.
                                                        let optionVotesSession = [];//Each element of array contain votes for each option for each voting session..
                                                        let optionPercentageSession = [];//Each element of array contain percentage for each option for each voting session..
                                                        let totalVotesSession = [];//Each element of array contain total votes for each voting session.

                                                        //Initialize votes and total votes for each voting session.
                                                        for (var j = 0; j < votingListCallback.length; j++) {
                                                            optionVotesSession.push([]);
                                                            optionPercentageSession.push([]);
                                                            totalVotesSession.push(0);
                                                
                                                            for (var k = 0; k < callbackOptionsList.length; k++) {
                                                                optionVotesSession[j].push(0);
                                                                optionPercentageSession[j].push(0);
                                        }
                                    }

                                                        for (var x = 0; x < callbackOptionsList.length; x++) {

                                                            let optionData = [];

                                    var optionOrderType = [];
                                    if (questionData.optionDecorationValue === "Alphabet") {
                                        optionOrderType = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
                                    } else if (questionData.optionDecorationValue === "Number") {
                                        optionOrderType = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"];
                                    }

                                                if (optionOrderType.length !== 0) {
                                                                optionData.push(optionOrderType[x] + ". " + callbackOptionsList[x].text);
                                                            }
                                                            else {
                                                                optionData.push(callbackOptionsList[x].text);
                                                            }

                                                            finalOptionData.push(optionData);
                                                        }

                                                        //Aggregate votes/total votes for each option for each voting session.
                                                        //For all voting session.

                                                        for (var j = 0; j < archivedResponses.length; j++) {
                                                            //Voting Session.

                                                            var uniqueResponses = [];

                                                            //For each answer in voting session.
                                                            for (var m = 0; m < archivedResponses[j].length; m++) {
                                                                //Answer in voting session.

                                                                // we remove the repetitive participants id that may happen because of the multiple choices selection
                                                                if (!uniqueResponses.includes(archivedResponses[j][m].participantsId)) {
                                                                    //if the list of the participants does not have this participants id we add it to the list 
                                                                    uniqueResponses.push(archivedResponses[j][m].participantsId);
                                                                }

                                                                //Check option of vote
                                                                for (var k = 0; k < callbackOptionsList.length; k++) {
                                                                    if (archivedResponses[j][m].option === callbackOptionsList[k].id) {
                                                                        //Add answer vote to total votes x session.
                                                                        optionVotesSession[j][k]++;
                                                                        //totalVotesSession[j]++;
                                                                    }
                                                                }

                                                            }

                                                            totalVotesSession[j] = uniqueResponses.length;

                                                }
                                                
                                                        //Calculate percentage for each option for each voting session.
                                                        let maxValue = 0;
                                                        for (var j = 0; j < optionVotesSession.length; j++) {
                                                            for (var k = 0; k < optionVotesSession[j].length; k++) {
                                                                optionPercentageSession[j][k] = Math.round((optionVotesSession[j][k] / totalVotesSession[j]) * 100);
                                                                if (maxValue < optionPercentageSession[j][k]) {
                                                                    maxValue = optionPercentageSession[j][k];
                                                                }
                                                            }
                                                        }
                                                
                                                        maxValue = maxValue + 10;
                                           
                                                        for (var x = 0; x < votingListCallback.length; x++) {
                                                            var sessionTime = moment(votingListCallback[x].timeStamp.toDate()).format("HH:mm:ss");
                                                            if (!description.includes(sessionTime)) {
                                                                description.push(sessionTime);
                                                                description.push({ type: 'string', role: 'annotation' })
                                            }

                                                            for (var y = 0; y < finalOptionData.length; y++) {
                                                                finalOptionData[y].push(optionPercentageSession[x][y], optionPercentageSession[x][y].toString() + "%");
                                        }
                                    }

                                                        finalOptionData.forEach(element => {
                                                            dataList.push(element);
                                                        });

                                                        dataList[0][1] = "Pre Voting";
                                                        dataList[0][3] = "Post Voting";

                                    this.setState({
                                        ...this.state,
                                        currentEvent: callback,
                                        questionData: questionData,
                                        parentInfo: parentInfo,
                                        middleContainerStatus: "compareResults",
                                        votingSessionsListToCompare: votingListCallback,
                                        barChartData: dataList,
                                        maxValue,
                                                            // optionsToCompare:
                                    });                                         
                                }
                            });
                                            }
                                        }
                                    }
                                });
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                currentEvent: callback,
                                questionData: questionData,
                                parentInfo: parentInfo,
                                middleContainerStatus: "compareResults",
                                votingSessionsListToCompare: []
                            });
                        }
                    });
                }
                /*************** BACK HISTORY BUTTON ****************/
                window.onpopstate = () => {
                }
            });
        });
    }

    textResizeFunctionForComparison(question, optionsList) {
        var optionsFontSize = "";
        var optionsLineHeight = "";
        var longestCharLength = 0;
        var questionForOptionsFontSize = '';
        var questionForOptionsLineHeight = "";

        for (var i = 0; i < optionsList.length; i++) {
            if (longestCharLength < optionsList[i].text.length) {
                longestCharLength = optionsList[i].text.length
            }
        }

        if (optionsList.length > 11) {
            questionForOptionsFontSize = '2vw';
            questionForOptionsLineHeight = '47px';
            optionsFontSize = "35px";
            optionsLineHeight = '37px';
        } else if (optionsList.length > 9 && optionsList.length < 12) {
            questionForOptionsFontSize = '43px';
            questionForOptionsLineHeight = '45px';
            optionsFontSize = "33px";
            optionsLineHeight = '35px';
        } else if (optionsList.length >= 5 && optionsList.length < 10) {
            questionForOptionsFontSize = '2.8vw';         //2vw
            questionForOptionsLineHeight = '3.0vw';     //4.5vw
            optionsFontSize = "25";                  //2.8vw
            optionsLineHeight = '20';                //3.2vw
        } else {
            questionForOptionsFontSize = '2.8vw';       //2.8
            questionForOptionsLineHeight = '3.0vw';       //3
            optionsFontSize = "25";                     //25
            optionsLineHeight = '20';                   //20
        }

        this.setState({
            // optionCol: 'col-6',
            // progressCol: 'col-6',
            // progressBarWidth: '100%',
            questionForOptionsFontSize,
            questionForOptionsLineHeight,
            optionsFontSize,
            optionsLineHeight,
        });

    }

    textResizeFunction(question, questionOptions) {
        var overFlowY = "hidden";
        var progressbarHeight = "";
        var longestCharLength = 0;
        var optionsFontSize = "";
        var optionsPadding = "";
        var optionsLineHeight = "";
        var optionsContainerPaddingTop = "";
        var questionForOptionsFontSize = '';
        var questionForOptionsLineHeight = "";
        var optionsTextIndent = "-30px";
        var optionsPaddingLeft = "35px";
        // var optionsTextIndent = "0px";
        var optionBulletFontSize = "";

        for (var i = 0; i < questionOptions.length; i++) {
            if (questionOptions[i]) {
                if (longestCharLength < questionOptions[i].text.length) {
                    longestCharLength = questionOptions[i].text.length
                }
            }
        }

        if (questionOptions.length > 15) {
            overFlowY = "scroll";
            questionForOptionsFontSize = '45px';
            questionForOptionsLineHeight = '47px';
            optionsFontSize = "35px";
            optionBulletFontSize = "22px";
            optionsLineHeight = '37px';
            progressbarHeight = '50px';
            optionsPadding = '6px';
            optionsContainerPaddingTop = "10px";
        } else if (questionOptions.length > 11) {
            questionForOptionsFontSize = '2.4vw';
            questionForOptionsLineHeight = '2.5vw';
            optionsFontSize = "2.0vw";
            optionBulletFontSize = "22px";
            optionsLineHeight = '2.4vw';
            progressbarHeight = '3.2vh';
            optionsPadding = '6px';
            optionsContainerPaddingTop = "10px";
        } else if (questionOptions.length > 9) {
            questionForOptionsFontSize = '2.4vw';
            questionForOptionsLineHeight = '2.5vw';
            optionsFontSize = "2.0vw";
            optionBulletFontSize = "20px";
            optionsLineHeight = '2.4vw';
            progressbarHeight = '3.2vh';
            optionsPadding = '6px';
            optionsContainerPaddingTop = "8px";
        } else if (questionOptions.length >= 5) {
            questionForOptionsFontSize = '2.4vw';   //3.5   (original values)
            questionForOptionsLineHeight = '2.5vw';   //4
            optionsFontSize = "1.5vw";              //2.8
            optionBulletFontSize = "1.5vw";           //25px
            optionsLineHeight = '1.5vw';            //3.2
            // progressbarHeight = '60px';
            progressbarHeight = '50px';             //50px
            optionsPadding = '13px';                //20px
            optionsContainerPaddingTop = "20px";    //20px
        } else if (longestCharLength > 200) {
            questionForOptionsFontSize = '2.4vw';     //3
            questionForOptionsLineHeight = '2.5vw'; //3.3
            optionsFontSize = "2.3vw";              //2.3
            optionBulletFontSize = "25px";           //2.3
            optionsLineHeight = '2.4vw';            //2.3
            progressbarHeight = '50px';             //50
            optionsPadding = '20px';                //10
            optionsContainerPaddingTop = "20px";    //10
        } else {
            questionForOptionsFontSize = '2.4vw';   //3.5
            questionForOptionsLineHeight = '2.5vw'; //3.8
            optionsFontSize = "2.3vw";              //2.7
            optionBulletFontSize = "25px";           //25
            optionsLineHeight = '2.4vw';            //3
            progressbarHeight = '50px';             //50
            optionsPadding = '20px';                //10
            optionsContainerPaddingTop = "20px";    //10
        }

        if (question.type === 'RatingQuestion') {
            optionsLineHeight = '2.8vw';
        }

        if (longestCharLength > 54) {
            this.setState({
                progressbarHeight,
                optionCol: 'col-6',
                progressCol: 'col-6',
                progressBarWidth: '100%',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionsPaddingLeft,
                optionBulletFontSize,
            });
        } else if (longestCharLength >= 10 && longestCharLength < 55) {
            this.setState({
                progressbarHeight,
                optionCol: 'col-5 ',
                progressCol: 'col-7 ',
                progressBarWidth: '100%',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionsPaddingLeft,
                optionBulletFontSize,
            });
        } else if (longestCharLength >= 2 && longestCharLength < 5) {
            this.setState({
                progressbarHeight,
                progressBarWidth: '100%',
                optionCol: ' col-4 ',
                progressCol: 'col-8 ',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionsPaddingLeft,
                optionBulletFontSize,
            });
        } else if (longestCharLength === 1) {
            //like and dislike options without title
            this.setState({
                progressbarHeight,
                progressBarWidth: '100%',
                optionCol: 'col-1',
                progressCol: 'col-11',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionsPaddingLeft,
                optionBulletFontSize,
            });
        } else {
            this.setState({
                progressbarHeight,
                progressBarWidth: '100%',
                optionCol: 'col-3',
                progressCol: 'col-9',
                overFlowY,
                optionsFontSize,
                optionsPadding,
                optionsLineHeight,
                optionsContainerPaddingTop,
                questionForOptionsFontSize,
                questionForOptionsLineHeight,
                optionsTextIndent,
                optionsPaddingLeft,
                optionBulletFontSize,
            });
        }
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            if (this.state.currentEvent.screenNumber === "One" && this.state.parentInfo.singleScreenElementId === this.state.parentInfo.currentQuestionElement) {
                this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/question/" + this.state.parentInfo.currentQuestionElement);
            } else {
                this.props.history.push("/event/" + this.state.eventId + "/element/" + this.state.idElement + "/tweet/" + this.state.parentInfo.currentTweetElement);
            }
        }
    }

    render() {
        let optionsListComponents = [];
        let totalVote = 0;
        var tweetCardSize;
        let liveTweetsListComponents = [];
        let approvedTweetsListComponents = [];
        var optionOrderListType;
        let ratingComponent;
        // if (this.state.screenType === 'question') {
        // if (this.state.currentEvent.screenNumber === "One" && this.state.parentInfo.singleScreenElementId === this.state.parentInfo.currentQuestionElement) {

        let selectedLanguage = this.state.currentEvent.selectedLanguage === 'English' ? englishStrings : italianStrings;

        if (this.state.optionsArrayLive) {
            if (this.state.responsesArray) {
                if (this.state.questionData.type === 'RatingQuestion') {
                    let totalRating = 0;
                    this.state.responsesArray.map(response => totalRating += parseInt(response.ratingNumber));
                    totalRating = this.state.responsesArray.length === 0 ? 0 : totalRating / this.state.responsesArray.length;
                    let inv = 1.0 / 0.5;
                    if (totalRating > 0) {
                        totalRating = Math.round(totalRating * inv) / inv;
                    }
                    ratingComponent =
                        <div id="cardBodyAnswerLiveScreen" className="card-text cardBodyAnswer">
                            <div className="row justify-content-center">
                                <div className="mr-5"
                                     style={{fontSize: "55px", color: this.state.currentEvent.primaryColor}}>
                                    <b> {selectedLanguage.average_votes}: {totalRating} </b>
                                </div>
                                <div className="ml-5">
                                    <StarRatingComponent
                                        name="rate1"
                                        starCount={parseInt(this.state.questionData.numberOfStars)}
                                        renderStarIcon={() => <span><i
                                            className="fas fa-star starIconRating"></i></span>}
                                        emptyStarColor={"grey"}
                                        value={totalRating}
                                        renderStarIcon={(index, value) => {
                                            return (
                                                <span>
                                                        <i className={index <= value ? 'fas fa-star' : 'far fa-star'}/>
                                                    </span>
                                            );
                                        }}
                                        renderStarIconHalf={() => {
                                            return (
                                                <span>
                                                        <span style={{position: 'absolute'}}><i
                                                            className="far fa-star"/></span>
                                                        <span>
                                                            <i className="fas fa-star-half text-warning"/></span>
                                                    </span>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                } else if (this.state.responsesArray.length === 0) {
                    optionsListComponents = (this.state.optionsArrayLive || []).map((option, i) => {
                        var optionOrderType;
                        if (this.state.questionData.optionDecorationValue === "Alphabet") {
                            optionOrderType = "a"
                        } else if (this.state.questionData.optionDecorationValue === "Number") {
                            optionOrderType = "1"
                        } else if (this.state.questionData.optionDecorationValue === "Bullet") {
                            optionOrderType = "disc"
                        }
                        return (
                            <div className="row" style={{padding: this.state.optionsPadding}} key={i}>
                                {(this.state.currentEvent.voteResultsDisplay === "Percentage") ? (

                                    <form className="col-12">
                                        <div className="row align-items-center">
                                            {(option.text === " ") ? (

                                                (option.type === "like") ? (

                                                    <div className={"optionDiv pb-2 pl-4  " + this.state.optionCol}>
                                                        <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                    </div>

                                                ) : (

                                                    <div className={"optionDiv pt-2 pl-4  " + this.state.optionCol}>
                                                        <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                    </div>

                                                )

                                            ) : (

                                                <div className={"optionDiv mb-1 text-left " + this.state.optionCol}>
                                                    {(this.state.questionData.optionDecorationValue === "None") ? (
                                                        <div>{option.text}</div>
                                                    ) : (
                                                        <li type={optionOrderType} id={option.id}>{option.text}</li>
                                                    )}
                                                </div>

                                            )}
                                            <div className={"progressBarDiv pr-2  " + this.state.progressCol}>
                                                <div className="col-9">
                                                    <Progress id="screenProgress" animated style={{
                                                        width: this.state.progressBarWidth,
                                                        height: this.state.progressbarHeight,
                                                        marginRight: '10px'
                                                    }} aria-valuenow="30">
                                                        <div className="progress-bar " role="progressbar"
                                                             name="progress" style={{
                                                            height: this.state.progressbarHeight,
                                                            backgroundColor: this.state.currentEvent.textColor
                                                        }} role="progressbar" aria-valuenow="90"></div>
                                                    </Progress>
                                                </div>
                                                <div className="col-3 text-left px-0">0%</div>
                                            </div>
                                        </div>
                                    </form>

                                ) : (

                                    <form className="col-12">
                                        <div className="row align-items-center">
                                            {(option.text === " ") ? (
                                                (option.type === "like") ? (

                                                    <div
                                                        className={"optionDiv pb-2 pl-4 optionDivMob " + this.state.optionCol}>
                                                        <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                    </div>

                                                ) : (

                                                    <div
                                                        className={"optionDiv pt-2 pl-4 optionDivMob " + this.state.optionCol}>
                                                        <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                    </div>

                                                )

                                            ) : (

                                                <div className={"optionDiv mb-1 " + this.state.optionCol}>
                                                    {(this.state.questionData.optionDecorationValue === "None") ? (
                                                        <div>{option.text}</div>
                                                    ) : (
                                                        <li type={optionOrderType} id={option.id}>{option.text}</li>
                                                    )}
                                                </div>

                                            )}
                                            <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                <div className="col-9">
                                                    <Progress id="screenProgress" animated style={{
                                                        width: this.state.progressBarWidth,
                                                        height: this.state.progressbarHeight,
                                                        marginRight: '10px'
                                                    }} aria-valuenow="30">
                                                        <div className="progress-bar " role="progressbar"
                                                             name="progress" style={{
                                                            height: this.state.progressbarHeight,
                                                            backgroundColor: this.state.currentEvent.textColor
                                                        }} role="progressbar" aria-valuenow="90"></div>
                                                    </Progress>
                                                </div>
                                                <div className="col-3 text-left px-0"> 0</div>
                                            </div>
                                        </div>
                                    </form>

                                )}
                            </div>
                        )
                    });
                } else {
                    // we remove the repetitive participants id that may happen because of the multiple choices selection
                    var uniqueResponses = [];
                    for (var index = 0; index < this.state.responsesArray.length; index++) {
                        if (!uniqueResponses.includes(this.state.responsesArray[index].participantsId)) {
                            //if the list of the participants does not have this participants id we add it to the list 
                            uniqueResponses.push(this.state.responsesArray[index].participantsId);
                        }
                    }
                    totalVote += uniqueResponses.length;

                    var progressBarWidth;
                    if (totalVote < 10) {
                        progressBarWidth = '98%'
                    } else if (9 < totalVote < 100) {
                        progressBarWidth = '90%'
                    } else {
                        progressBarWidth = '85%'
                    }
                    var sortedOptions = [];
                    //Check the user settings for ordering the question options based on the number of votes
                    if (this.state.currentEvent.optionsOrderStatus === 'Active') {
                        for (var i = 0; i < this.state.optionsArrayLive.length; i++) {
                            var itemVote = 0;
                            for (let index in this.state.responsesArray) {
                                if (this.state.responsesArray[index].option === this.state.optionsArrayLive[i].id) {
                                    itemVote += 1;
                                }
                            }
                            sortedOptions.push({
                                text: this.state.optionsArrayLive[i].text,
                                votes: itemVote,
                                isAnswer: this.state.optionsArrayLive[i].isAnswer,
                                type: this.state.optionsArrayLive[i].type
                            })
                        }
                        sortedOptions.sort(function (a, b) {
                            return a.votes > b.votes ? -1 : 1
                        });
                        optionsListComponents = (sortedOptions || []).map((option, i) => {
                            var optionOrderType;
                            if (this.state.questionData.optionDecorationValue === "Alphabet") {
                                optionOrderType = "a"
                            } else if (this.state.questionData.optionDecorationValue === "Number") {
                                optionOrderType = "1"
                            } else if (this.state.questionData.optionDecorationValue === "Bullet") {
                                optionOrderType = "disc"
                            }

                            var voteBarColor;
                            if (option.isAnswer === true && this.state.currentEvent.showCorrectAnswerColor === "show" && ((this.state.parentInfo.bigScreenStatus.status === "archivedQuestion") || (this.state.parentInfo.bigScreenStatus.status === "liveQuestion" && this.state.parentInfo.currentLiveSession.status === "stop"))) {
                                voteBarColor = this.state.currentEvent.correctQuestionColor;
                            } else {
                                voteBarColor = this.state.currentEvent.backgroundTextColor;
                            }
                            return (
                                <div className="row" style={{padding: this.state.optionsPadding}} key={i}>
                                    {(this.state.currentEvent.voteResultsDisplay === "Percentage") ? (

                                        <form className="col-12 text-right">
                                            <div className="row align-items-center">
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div className={"optionDiv pb-2 pl-4 " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                        </div>

                                                    ) : (

                                                        <div className={"optionDiv pt-2 pl-4 " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div className={"optionDiv mb-1 " + this.state.optionCol}>
                                                        {(this.state.questionData.optionDecorationValue === "None") ? (
                                                            <div>{option.text}</div>
                                                        ) : (
                                                            <li type={optionOrderType} id={option.id}>{option.text}</li>
                                                        )}
                                                    </div>

                                                )}
                                                <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                    <div className="col-9">
                                                        <Progress id="screenProgress" animated style={{
                                                            width: this.state.progressBarWidth,
                                                            height: this.state.progressbarHeight,
                                                            marginRight: '10px'
                                                        }} aria-valuenow="30"
                                                                  value={Math.round((option.votes / totalVote) * 100)}>
                                                            <div className="progress-bar" role="progressbar"
                                                                 name="progress" style={{
                                                                height: this.state.progressbarHeight,
                                                                backgroundColor: voteBarColor
                                                            }} role="progressbar" aria-valuenow="90"></div>
                                                        </Progress>
                                                    </div>
                                                    <div
                                                        className="col-3 text-left px-0"> {Math.round(((option.votes) / totalVote) * 100)}%
                                                        jj
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    ) : (

                                        <form className="col-12">
                                            <div className="row align-items-center">
                                                {/* <div className={"optionDiv mb-1 " + this.state.optionCol} >
                                                    {option.text}
                                                </div> */}
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div className={"optionDiv pb-2 pl-4 " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                        </div>

                                                    ) : (

                                                        <div className={"optionDiv pt-2 pl-4 " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div className={"optionDiv mb-1 " + this.state.optionCol}>
                                                        {(this.state.questionData.optionDecorationValue === "None") ? (
                                                            <div>{option.text}</div>
                                                        ) : (
                                                            <li type={optionOrderType} id={option.id}>{option.text}</li>
                                                        )}
                                                    </div>

                                                )}
                                                <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                    <Progress id="screenProgress" animated style={{
                                                        width: this.state.progressBarWidth,
                                                        height: this.state.progressbarHeight,
                                                        marginRight: '10px'
                                                    }} aria-valuenow="30" max={totalVote} value={option.votes}>
                                                        <div className="progress-bar " role="progressbar"
                                                             name="progress" style={{
                                                            height: this.state.progressbarHeight,
                                                            backgroundColor: voteBarColor
                                                        }} role="progressbar" aria-valuenow="90"></div>
                                                    </Progress>
                                                    <div className="pl-1">{option.votes}</div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            )
                        });
                    } else {
                        optionsListComponents = (this.state.optionsArrayLive || []).map((option, i) => {
                            var optionOrderType;
                            if (this.state.questionData.optionDecorationValue === "Alphabet") {
                                optionOrderType = "a"
                            } else if (this.state.questionData.optionDecorationValue === "Number") {
                                optionOrderType = "1"
                            } else if (this.state.questionData.optionDecorationValue === "Bullet") {
                                optionOrderType = "disc"
                            }

                            var voteBarColor;
                            if (option.isAnswer === true && this.state.currentEvent.showCorrectAnswerColor === "show" && ((this.state.parentInfo.bigScreenStatus.status === "archivedQuestion") || (this.state.parentInfo.bigScreenStatus.status === "liveQuestion" && this.state.parentInfo.currentLiveSession.status === "stop"))) {
                                voteBarColor = this.state.currentEvent.correctQuestionColor;
                            } else {
                                voteBarColor = this.state.currentEvent.backgroundTextColor;
                            }
                            itemVote = 0;
                            for (let index in this.state.responsesArray) {
                                if (this.state.responsesArray[index].option === option.id) {
                                    itemVote += 1;
                                }
                            }

                            return (
                                <div className="row " style={{padding: this.state.optionsPadding}} key={i}>
                                    {(this.state.currentEvent.voteResultsDisplay === "Percentage") ? (

                                        <form className="col-12">
                                            <div className="row align-items-center">
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div
                                                            className={"optionDiv pb-2 pl-4 optionDivMob " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                        </div>

                                                    ) : (

                                                        <div
                                                            className={"optionDiv pt-2 pl-4 optionDivMob " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div
                                                        className={"optionDiv mb-1 optionDivMob " + this.state.optionCol}>
                                                        {(this.state.questionData.optionDecorationValue === "None") ? (
                                                            <div style={{color: voteBarColor}}>{option.text}</div>

                                                        ) : (

                                                            <li type={optionOrderType} id={option.id}
                                                                style={{color: voteBarColor}}>{option.text}</li>
                                                        )}
                                                    </div>
                                                )}
                                                <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                    <div className="col-9">
                                                        <Progress id="screenProgress" animated style={{
                                                            width: this.state.progressBarWidth,
                                                            height: this.state.progressbarHeight,
                                                            marginRight: '10px'
                                                        }} aria-valuenow="30"
                                                                  value={Math.round((itemVote / totalVote) * 100)}>
                                                            <div className="progress-bar" role="progressbar"
                                                                 name="progress" style={{
                                                                height: this.state.progressbarHeight,
                                                                backgroundColor: voteBarColor
                                                            }} role="progressbar" aria-valuenow="90"></div>
                                                        </Progress>
                                                    </div>
                                                    <div className="col-3 text-left px-0"
                                                         style={{
                                                             fontSize: this.state.optionsFontSize,
                                                             color: voteBarColor
                                                         }}>
                                                        {Math.round(((itemVote) / totalVote) * 100)}%
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    ) : (

                                        <form className="col-12">
                                            <div className="row align-items-center">
                                                {(option.text === " ") ? (

                                                    (option.type === "like") ? (

                                                        <div className={"optionDiv pb-2 pl-4  " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-up optionLikeIconBigScreen pr-2 text-success"></i>
                                                        </div>

                                                    ) : (

                                                        <div className={"optionDiv pt-2 pl-4  " + this.state.optionCol}>
                                                            <i className="fas fa-thumbs-down optionLikeIconBigScreen pr-2 text-danger"></i>
                                                        </div>

                                                    )

                                                ) : (

                                                    <div className={"optionDiv mb-1  " + this.state.optionCol}>
                                                        {(this.state.questionData.optionDecorationValue === "None") ? (
                                                            <div>{option.text}</div>
                                                        ) : (
                                                            <li type={optionOrderType} id={option.id}>{option.text}</li>
                                                        )}
                                                    </div>

                                                )}
                                                <div className={"progressBarDiv pr-2 " + this.state.progressCol}>
                                                    <div className="col-9">
                                                        <Progress id="screenProgress" animated style={{
                                                            width: this.state.progressBarWidth,
                                                            height: this.state.progressbarHeight,
                                                            marginRight: '10px'
                                                        }} aria-valuenow="30" max={totalVote} value={itemVote}>
                                                            <div className="progress-bar " role="progressbar"
                                                                 name="progress" style={{
                                                                height: this.state.progressbarHeight,
                                                                backgroundColor: voteBarColor
                                                            }} role="progressbar" aria-valuenow="90"></div>
                                                        </Progress>
                                                    </div>
                                                    <div className="col-3 text-left px-0"> {itemVote} </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            )
                        });
                    }
                }
            }
        }
        if (this.state.currentEvent.screenNumber === "One" && this.state.parentInfo.singleScreenElementId === this.state.parentInfo.currentTweetElement) {
            if (this.state.currentEvent.autoApproveTweets === "Without Moderator") {
                if (this.state.allTweetsWithoutModerator.length > 0) {
                    liveTweetsListComponents = (this.state.allTweetsWithoutModerator || []).map((tweet, i) => {

                        return (
                            <div className="row mb-3" key={i}>
                                {(tweet.bArchived === false) ? (

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="card">
                                            <div className="cardTextTweet">
                                                <div className="cardTextTweetHeader px-3 text-white py-2"
                                                     style={{backgroundColor: this.state.currentEvent.primaryColor}}>
                                                    <i className="fas fa-user mr-2"
                                                       style={{color: this.state.currentEvent.textColor}}></i>
                                                </div>
                                                <div className="cardTextTweetBody px-3 pb-2 pt-2"
                                                     style={{color: this.state.currentEvent.primaryColor}}>
                                                    {tweet.sText}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )

                    });
                }
            } else {
                if (this.state.currentEvent.tweetsToShow === "Approved") {
                    if (this.props.approvedTweetsList) {
                        liveTweetsListComponents = (this.props.approvedTweetsList || []).map((tweet, i) => {
    
                            return (
                                <div className="row mb-3" key={i}>
                                    {(tweet.bArchived === false) ? (
    
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="card">
                                                <div className="cardTextTweet">
                                                    <div className="cardTextTweetHeader px-3 text-white py-2"
                                                         style={{backgroundColor: this.state.currentEvent.primaryColor}}>
                                                        <i className="fas fa-user mr-2"
                                                           style={{color: this.state.currentEvent.textColor}}></i>
                                                    </div>
                                                    <div className="cardTextTweetBody px-3 pb-2 pt-2"
                                                         style={{color: this.state.currentEvent.primaryColor}}>
                                                        {tweet.sText}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            )
                        });
                    }
                }
                else if (this.state.currentEvent.tweetsToShow === "Live") {
                if (this.props.liveTweetsList) {
                    liveTweetsListComponents = (this.props.liveTweetsList || []).map((tweet, i) => {

                        return (
                            <div className="row mb-3" key={i}>
                                {(tweet.bArchived === false) ? (

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="card">
                                            <div className="cardTextTweet">
                                                <div className="cardTextTweetHeader px-3 text-white py-2"
                                                     style={{backgroundColor: this.state.currentEvent.primaryColor}}>
                                                    <i className="fas fa-user mr-2"
                                                       style={{color: this.state.currentEvent.textColor}}></i>
                                                </div>
                                                <div className="cardTextTweetBody px-3 pb-2 pt-2"
                                                     style={{color: this.state.currentEvent.primaryColor}}>
                                                    {tweet.sText}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )
                    });
                }
            }
        }
        }

        var orderedOptionsListcomponent;
        if (this.state.questionData.optionDecorationValue === "Alphabet" || this.state.questionData.optionDecorationValue === "Number") {
            orderedOptionsListcomponent = <ol>{optionsListComponents}</ol>
        } else if (this.state.questionData.optionDecorationValue === "Bullet") {
            orderedOptionsListcomponent = <ul>{optionsListComponents}</ul>
        } else {
            orderedOptionsListcomponent = <div>{optionsListComponents}</div>
        }

        var middleContainerContent;
        if (this.state.middleContainerStatus === "questionContainer") {
            middleContainerContent = (
                <div className="containerMiddle" id="containerMiddle"
                     style={{
                         color: this.state.currentEvent.backgroundTextColor,
                         overflowY: this.state.overFlowY,
                     }}
                >
                    {/* {(this.state.status !== 'NoLiveSession') ? ( */}
                    <div id="containerMiddleFont">
                        {(this.state.questionData && (this.state.questionData.type === "MultipleChoice" || this.state.questionData.type === "SimpleTextAndMultipleChoice" || this.state.questionData.type === "RatingQuestion")) ? (

                            <div>
                                <div className="row mb-4 mt-4 ">
                                    {(optionsListComponents.length !== 0 || orderedOptionsListcomponent.length !== 0) ? (

                                        <div className="col-12 text-left"
                                             style={{
                                                 fontSize: this.state.questionForOptionsFontSize,
                                                 lineHeight: this.state.questionForOptionsLineHeight
                                             }}
                                        >
                                            {/* <label style={{ fontSize: this.state.questionSize }}><b>{this.state.questionData.text}</b></label> */}
                                            <b>{this.state.questionData.text}</b>
                                        </div>

                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <div
                                    className={`row ${this.state.questionData.type !== "RatingQuestion" ? 'ml-4' : ''}`}
                                    style={{
                                        color: this.state.currentEvent.backgroundTextColor,
                                        paddingTop: this.state.optionsContainerPaddingTop,
                                        fontSize: this.state.optionsFontSize,
                                        lineHeight: this.state.optionsLineHeight
                                    }}
                                >
                                    <div
                                        className={`col-12 ${this.state.questionData.type !== "RatingQuestion" ? 'pl-3' : ''}`}>
                                        {this.state.questionData.type === "RatingQuestion" ? ratingComponent : orderedOptionsListcomponent}
                                    </div>
                                </div>
                            </div>

                        ) : (

                            <div className="col-12" style={{color: this.state.currentEvent.backgroundTextColor}}>
                                <div className="row mt-3"
                                     style={{
                                         fontSize: "45px",
                                         lineHeight: "55px"
                                     }}
                                >
                                    <div className="col-12"><b>{this.state.questionData.text}</b></div>
                                </div>
                                {(this.state.parentInfo && this.state.parentInfo.bigScreenStatus && this.state.parentInfo.bigScreenStatus.status === "liveQuestion") ? (

                                    <div className="col-12 mt-4 px-0">
                                        <AnswetTextLive
                                            question={this.state.questionData}
                                            responsesArrayLive={this.state.responsesArrayLive}
                                            event={this.state.currentEvent}
                                            parentInfo={this.state.parentInfo}
                                            page={"LiveScreen"}
                                        />
                                    </div>

                                ) : (

                                    <div className="col-12 mt-4 px-0">
                                        <AnswerText
                                            question={this.state.questionData}
                                            responsesArray={this.state.responsesArraySimpleText}
                                            event={this.state.currentEvent}
                                            parentInfo={this.state.parentInfo}
                                            page={"LiveScreen"}
                                        />
                                    </div>

                                )}
                            </div>
                        )}
                    </div>
                </div>
            )
        } else if (this.state.middleContainerStatus === "compareResults") {
            if (this.state.votingSessionsListToCompare.length > 0) {
                middleContainerContent = (
                    <div className="containerMiddle" id="containerMiddle"
                         style={{color: this.state.currentEvent.backgroundTextColor, overflowY: this.state.overFlowY}}>
                        <div id="containerMiddleFont">
                            <LiveCompareResultsComponent
                                // votingSessionsListToCompare ={this.state.votingSessionsListToCompare}
                                questionData={this.state.questionData}
                                currentEvent={this.state.currentEvent}
                                barChartData={this.state.barChartData}
                                maxValue={this.state.maxValue}
                                questionForOptionsFontSize={this.state.questionForOptionsFontSize}
                                questionForOptionsLineHeight={this.state.questionForOptionsLineHeight}
                                optionsFontSize={this.state.optionsFontSize}
                                optionsLineHeight={this.state.optionsLineHeight}
                                optionsToCompare={this.state.optionsToCompare}
                            />
                        </div>
                    </div>
                )
            } else {
                middleContainerContent = (<div></div>)
            }
        } else if (this.state.middleContainerStatus === "tweetContainer") {
            middleContainerContent = (
                <div className="containerMiddleTweet">
                    <div className="row mb-5" id="containerMiddleFont">
                        {(liveTweetsListComponents.length > 0) ? (
                            //  {/* the setting is on two screen mode so we do not have anything to show on the single live screen mode. */}
                            <div className="col-12">
                                <div className="mt-3">
                                    <div className="mb-3 headerTextStyle d-none"
                                         style={{color: this.state.currentEvent.backgroundTextColor}}>
                                        <b>Live Tweets</b>

                                        {/* <div className="borderLine" style={{ backgroundColor: this.state.currentEvent.backgroundTextColor }}></div> */}
                                    </div>
                                    {liveTweetsListComponents}
                                </div>
                            </div>

                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            )
        }

        return (
            <div style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                {(this.state.currentEvent === 'default') ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                ) : (

                    (this.state.currentEvent.showIntroductionPage === "Yes") ? (

                        <div className="row containerIntroduction text-center"
                             style={{backgroundColor: this.state.currentEvent.backgroundColor}}>
                            <IntroductionPage
                                currentEventToIntroduction={this.state.currentEvent}
                            />
                        </div>

                    ) : (

                        <div className="LiveScreenContainer">
                            <div className="headerBigScreen"
                                 style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}>
                                {(this.state.currentEvent.voteCounterStatus === 'Active' && (this.state.questionData.type === "MultipleChoice" || this.state.questionData.type === "SimpleTextAndMultipleChoice")) ? (

                                    <div className="row">
                                        <div className="col-9 text-left p-1">
                                            {(this.state.currentEvent.eventLogoUrl !== "default") ? (

                                                <form className="form-inline pl-4">
                                                    <div className="logoHeader">
                                                        <img src={this.state.currentEvent.eventLogoUrl}
                                                             className='img-fluid img-thumbnail' alt="Logo"
                                                             style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                    </div>
                                                    {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                        <div className="bigScreenTitle"
                                                             style={{color: this.state.currentEvent.headerTextColor}}>
                                                            {this.state.currentEvent.title}
                                                        </div>

                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </form>

                                            ) : (

                                                <form className="form-inline pl-4">
                                                    <div className="logoHeader pl-3">
                                                        {/* <img src={"/files/LogoTemp.png"} className='img-fluid img-thumbnail' alt="Logo" style={{ backgroundColor: this.state.currentEvent.headerBackgroundColor }} /> */}
                                                    </div>
                                                    {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                        <div>
                                                            <div className="bigScreenTitle"
                                                                 style={{color: this.state.currentEvent.headerTextColor}}>
                                                                {this.state.currentEvent.title}
                                                            </div>
                                                        </div>

                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </form>

                                            )}
                                        </div>
                                        <div className="col-3 text-center pt-4">
                                            <div className="bigScreenVoteNo"
                                                 style={{color: this.state.currentEvent.headerTextColor}}>Votes No.
                                                : {totalVote} </div>
                                        </div>
                                    </div>

                                ) : (

                                    <div className="row">
                                        <div className="col-12 text-left p-1">
                                            {(this.state.currentEvent.eventLogoUrl !== "default") ? (

                                                <form className="form-inline pl-4">
                                                    <div className="logoHeader">
                                                        <img src={this.state.currentEvent.eventLogoUrl}
                                                             className='img-fluid img-thumbnail' alt="Logo"
                                                             style={{backgroundColor: this.state.currentEvent.headerBackgroundColor}}/>
                                                    </div>
                                                    {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                        <div>
                                                            <div className="bigScreenTitle"
                                                                 style={{color: this.state.currentEvent.headerTextColor}}>
                                                                {this.state.currentEvent.title}
                                                                {/* (#{this.state.currentEvent.eventCode}) */}
                                                            </div>
                                                        </div>

                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </form>

                                            ) : (

                                                <form className="form-inline pl-4">
                                                    <div className="logoHeader pl-3">
                                                        {/* <img src={"/files/LogoTemp.png"} className='img-fluid img-thumbnail' alt="Logo" style={{ backgroundColor: this.state.currentEvent.headerBackgroundColor }} /> */}
                                                    </div>
                                                    {(this.state.currentEvent.showEventInfoStatus === "Active") ? (

                                                        <div>
                                                            <div className="bigScreenTitle"
                                                                 style={{color: this.state.currentEvent.headerTextColor}}>
                                                                    {this.state.currentEvent.title}
                                                            </div>
                                                        </div>

                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </form>

                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* Container Middle Content */}
                            {(this.state.currentEvent.themeBigScreenImageUrl === "default") ? (

                                <div>
                                    {middleContainerContent}
                                </div>

                            ) : (

                                <div className="imageTheme"
                                     style={{backgroundImage: "url(" + this.state.currentEvent.themeBigScreenImageUrl + ")"}}>
                                    {middleContainerContent}
                                </div>

                            )}
                        </div>
                    )
                )}
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        eventData: state.bigScreenReducer.eventData,
        questionOptions: state.bigScreenReducer.questionOptions,
        questionData: state.bigScreenReducer.questionData,
        currentEvent: state.eventReducer.currentEvent,
        liveTweetsList: state.bigScreenReducer.liveTweetsList,
        approvedTweetsList: state.bigScreenReducer.approvedTweetsList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetEventInfoOnChange: (eventId, callback) => dispatch(eventActions.getEventInfoOnChange(eventId, callback)),
        ongetLiveSessionOptions: (eventId, elementId, questionId, liveSessionId, callback) => dispatch(bigScreenActions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback)),
        onGetQuestion: (eventId, elementId, questionId, callback) => dispatch(bigScreenActions.getQuestion(eventId, elementId, questionId, callback)),
        onGetQuestionInfo: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getQuestionInfo(eventId, idQuestionEl, questionId, callback)),
        onGetResponses: (eventId, idElement, questionId, votingId, callback) => dispatch(questionActions.getResponses(eventId, idElement, questionId, votingId, callback)),
        onGetArchivedResponses: (eventId, idQuestionEl, questionId, votingId, callback) => dispatch(questionActions.getArchivedResponses(eventId, idQuestionEl, questionId, votingId, callback)),
        onInitQuestionOptions: (eventId, idElement, questionId, callback) => dispatch(bigScreenActions.getAllQuestionOptionsScreen(eventId, idElement, questionId, callback)),
        onGetAllLiveTweetsList: (eventId, idElement, callback) => dispatch(bigScreenActions.getAllLiveTweetsList(eventId, idElement, callback)),
        onGetAllApprovedTweetsList: (eventId, idElement, callback) => dispatch(bigScreenActions.getAllApprovedTweetsList(eventId, idElement, callback)),
        onGetLastVotingSessionId: (eventId, idElement, questionId, callback) => dispatch(bigScreenActions.getLastVotingSessionId(eventId, idElement, questionId, callback)),
        onGetQuestionInfoOnChange: (eventId, idQuestionEl, questionId, callback) => dispatch(questionActions.getQuestionInfoOnChange(eventId, idQuestionEl, questionId, callback)),
        onGetParentInfoOnChange: (eventId, elementId, callback) => dispatch(eventActions.getParentInfoOnChange(eventId, elementId, callback)),
        onGetVotingSessionsToCompare: (eventId, idQuestionEl, question, sessionsList, callback) => dispatch(questionActions.getVotingSessionsToCompare(eventId, idQuestionEl, question, sessionsList, callback)),
        onGetAllTweets: (eventId, idTweetEl, callback) => dispatch(eventActions.getAllTweets(eventId, idTweetEl, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveScreen);