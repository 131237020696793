export const englishStrings = {

    /********************* MAIN SCREEN ******************************/

    add_new_event: ' New Event',

    /********************* EDIT MODAL ******************************/
    edit_event:'Edit Event',

    information: 'General',
    appearance:'Appearance',
    main_menu: 'Main Menu',
    custom_labels: 'Custom labels',
    questions:'Questions',
    tweets:'Tweets',
    agenda:'Agenda',

    event_title:'Event Title',
    event_code:'Event Code',
    starting_date:'Starting Date',
    ending_date:'Ending Date',

    update_qr_code:'Update Qr Code',
    download_qr_code:'Download Qr Code',
    cancel:'Cancel',
    update:'Update',
    ok:'Ok',
    create:'Create',
    yes:"Yes",
    no:"No",

    choose_language:"Choose Language",
    header_background_color:"Header Background Color",
    header_text_color:"Header Text Color",
    background_color:'Background Color',
    text_color:'Text Color',
    background_color_theme:'Background Color (Theme)',
    text_color_theme:'Text Color (Theme)',
    correct_question_color:'Correct Question Color (Progress Bar)',
    home_page:'Home Page *',
    intro_page: 'Intro Page *',
    logo:'Logo *',
    upload_note:'* Use drag and drop or click in the box to choose and upload image.',
    show_vote_counter:'Show Vote Counter:',
    order_question_ptions:'Order Question Options:',
    number_live_screens:'Number of Live Screens:',
    display_vote_results:'Display Vote Results in:',
    disable:'Disable',
    active: 'Enable',
    one:'One',
    two:'Two',
    percentage:'Percentage',
    numbers:'Numbers',

    show_event_info:"Show Event Info",

    show_tweets_participants:'Show Tweets to Participants',
    moderation: "Moderation",
    define_tweets_show_participants:'Define Tweets to Show to the Participants:',
    with_moderator:'With Moderator',
    without_moderator:'Without Moderator',
    live:'Live',
    approved:'Approved',

    available_languages: "Available languages",
    english:"English",
    italian:"Italian",

    define_event_title:' Please define a title for this event.',
    event_code_exists:'This Event Code already exists please choose another one!',

    survey_link_address:"Survey Link Address: ",
    agenda_link_address: "Agenda Link Address: ",

    qr_text: "Client QR Scanner Text",

    theme_big_screen:"Theme (Big Screen)",
    theme_participant:"Theme (Participant App)",
    participant_profile: "Participant profile",
    active_email:"Activate email in participants profile",
    active_profile:"Activate profile",

    show_header_bar: "Show Header Bar",

    automatic_jump_poll: 'Automatic jump to Poll:',    

    /********************* HEADER TAB ******************************/
    all_events:'ALL EVENTS',
    event_settings:"EVENT'S SETTINGS",
    current_event:"CURRENT EVENT",
    active_participants:"PARTICIPANTS",
    send_message:'SEND MESSAGE',
    hTweets:'TWEETS',
    hQuestions:'QUESTIONS',
    hSurvey:'SURVEY',
    your_events:"Your Events",
    shared_events:"Shared Events",

    /*********************** MESSAGE MODAL *********************/
    send_message_participants:"Send Message To Participants",
    message:"Message",
    title:"Title",
    importance:"Importance",
    high_priority:"High priority",
    normal:"Normal",
    note_message:"*Please define the message to send.",
    note_importance:"*Please define the importance of your message.",
    sendBtn:"Send",

    /*********************** SINGLE EVENT PAGE *********************/
    new_item:"New Item",
    eventItems: "Event Items",
    roomItems: "Room Items",
    external_content: 'External Content',
    survey:"Survey",
    question_answer: "Question & Answer",
    folder:"Folder",
    room:"Room (Hall)",
    library:"Library",
    qrcode:"QR Scanner",
    eventInfo:"Event Information",
    external_content_mob: 'External Content',
    survey_mob:"Survey",
    question_answer_mob: "Question",
    folder_mob:"Folder",
    room_mob:"Room",
    library_mob:"Library",
    qrcode_mob:"QR",
    eventInfo_mob:"Info",
    description_library:"This tool allows partecipant to visualize the event's library files",
    description_library1: "Library tool to store documents (eg. .pdf, .jpg...)",
    description_library2: "visualize the event's library files",
    description_qrcode:"This tool allows partecipant to open library files through qr code",
    description_qrcode1: "QRcode scanner tool to visualize documents",
    description_qrcode2: "open library files through qr code",
    description_eventInfo:"to be defined ....",
    description_eventInfo1: "to be defined ",
    description_eventInfo2: "to be defined",
    description_agenda: "This tool provides the ability to store globally agenda files/URLs of the event",
    description_agenda1: "Agenda tool to store files/URLs",
    description_agenda2: "store globally agenda files/URLs of the event",
    description_room:"Create a specific container in which to insert other elements (e.g. surveys, tweets, questions) to better organize activities in your event by room",
    description_room1: "Room container to structure the event based on the room",
    description_room2: "insert other elements (e.g. surveys, tweets, questions) to better organize activities in your event by room",
    description_external_content: 'This tool allows participants to open an external content',

    // DA CANCELLARE
    description_survey: "This tool allows participants to take part to a survey",
    description_survey1:"Survey tool to link external questionnaires",
    description_survey2:"take parrt to a survey",

    description_question:"This tool provides the ability for attendees to submit multiple choice or open text questions, and shows in real time the answer results to the main screen",
    description_question1:"Question tool to create questions sessions",
    description_question2:"submit multiple choice or open text questions, and shows in real time the answer results to the main screen",
    description_tweet:"This tool allows participants to submit questions or write comments to the speakers’s panel. Attendees messages can be moderated before sending to speakers",
    description_tweet1:"Tweet tool to allow sending and moderating tweets",
    description_tweet2:"submit questions or write comments to the speakers’s panel. Attendees messages can be moderated before sending to speakers",
    description_folder:"Create a generic container in which to insert other elements to better organize activities in your event. (e.g. ‘First session’, ‘Verification of learning’ , ‘Afternoon workshop’). This folder can contain items or other nested folders.",
    description_folder1:"Generic container to better organize the event",
    description_folder2:"insert other elements to better organize activities in your event. (e.g. ‘First session’, ‘Verification of learning’ , ‘Afternoon workshop’). This folder can contain items or other nested folders.",
    edit_room_item:"Edit Room Item",
    room_title:"Room title",
    select_room_icon_color:"Select Room's Icon Color",
    /*********************** QUESTIONS PAGE *********************/

    new_question: " New Question",
    list_questions:"LIST OF QUESTIONS",
    active_question:"ACTIVE QUESTION",
    archived_sessions:"ARCHIVED SESSIONS",
    live_session:"LIVE SESSION",

    delete_question_session_title:"Delete Question's Session",
    delete_question_session_text:"Are you sure you want to delete this Session?",

    /****************************** LIVE SCREEN ***************************/
    join_event_through: "Join this Event through",
    using_event_code: "Using Code",
    average_votes: "Average Votes",

    /************************* Library Screen ***************************/
    title_uploaded_files:"Uploaded Files: ",
    file_name:"File Name",
    drag_file_note_library1:"Click to select the file ",
    drag_file_note_library2:"or use Drag & Drop",
    edit_file_name:"Edit Name",
    remove_file:"Remove File",
    upload_file:"Upload File",
    drag_and_drop:"Select file(s) to upload or use Drag & Drop",
    file_url:"External File URL:",
    /**************************** Agenda Screen ************************/
    select_language:" Select Language",
    upload_title:"Upload the file(s) of the agenda or insert its link(s):",
    upload_agenda_file:"Upload File:",

    /**************************** Custom Labels Client************************/
    client_questions: 'Questions',
    client_polls: 'Polls',
    client_live_questions: 'Live questions',
    client_your_questions: 'Your questions',
    client_your_question: 'Your question',
    client_you_did_not_send_any_question_yet: 'You did not send any question yet.',
    client_write_a_new_question: 'Write a new question',
    client_live_poll: 'Live Poll',
    client_send_answer: 'Send Answer',
    client_select_language: 'Select Language',
    client_select_room: 'Select Room',
    client_questions_polls: 'Questions/Polls',
    client_logout: 'Logout',

    category_questions: 'QUESTIONS',
    category_polls: 'POLLS',
    category_main_menu: 'MAIN MENU',

    /**************************** Main Menu Client ************************/
    visible: 'Visible',
    home: 'Home',
    intro: 'Intro',
    questions_polls: 'Questions/Polls',
    logout: 'Logout'
}